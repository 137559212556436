<template>
  <div class="main-view-padding has-header">
      <div class="header main" v-header-position-lock>
          <div class="align-left">
          </div>
          <div class="align-middle">
                <headerbuttonstack :state="headerButtons"/>
          </div>
          <div class="align-right">
          </div>
      </div>
    <div style="font-size: 32px; text-align: left; color: #e51550; border-bottom:1px solid #ccc; padding: 5px; font-weight:400; margin-bottom:25px;">
      Neue Mitarbeiter Benachrichtigungen
    </div>


    <div class="scrollbar-container">
      <table class="table table-bordered" style="width:80%; margin:0 auto;">
        <thead>
          <tr>
            <th scope="col">DB Id</th>
            <th scope="col">Employee Id</th>
            <th scope="col">Email gesendet</th>
            <th scope="col">Reset</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="e in employees" :key="'empl_tr_'+e.id">
            <th scope="row">{{ e.id }}</th>
            <td>{{ e.employeeId }}</td>
            <td>{{ e.sentEmail }}</td>
            <td><button v-on:click="resetEmail(e.id)">Reset</button></td>
          </tr>
        </tbody>
      </table>
    </div>



  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
 components: { 

  },
  name: 'SuperadminNewEmployeeMail',
  data () {
    return {
      employees: [],
    }
  },
  created: function () {
  },
  mounted: function () {
    if(this.$store.state.jwt != undefined && this.$store.state.companyId != undefined){
        this.refresh();
    }
  },
  computed: {
    ...mapGetters(['companyId', 'isSuperUser']),
    headerButtons() {
      let view = this;
      let state= { buttons: [] };
      return state;
    },
  },
  watch: {
  },
  // methods that implement data logic.
  methods: {
    refresh: function(){
        this.loading = true;
        this.loadRows();
    },

    resetEmail(rowId){
      let view = this;
      let url = '/api/superuser/resetEmployeeNotificationEmail';
      
      const params = {
        companyId: this.$store.state.companyId,
        rowId: rowId,
      };
      this.axios({
        method: 'put',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        params,
      }).then(response => {
        view.loadRows();
      }).catch(function (error) {
        console.log(error);
      })
    },
    
    loadRows: function(force = false){
      let view = this;
      let url = '/api/superuser/getNewEmployees';
      
      const params = {
        companyId: this.$store.state.companyId
      };
      this.axios({
        method: 'get',
        headers: { 'AUTHORIZATION': "Bearer "+this.$store.state.jwt },
        url: url,
        params,
      }).then(response => {
        view.employees = response.data;
      }).catch(function (error) {
        console.log(error);
      })
    }
  },
}
</script>

<!-- Add "scoped" attriemployeete to limit CSS to this component only -->
<style scoped>

.xColumn3{
  width: 400px; 
  text-align:left; 
  float:left; 
  margin-left:25px; 
  margin-right:25px;
  overflow-y: auto;
  max-height: 800px;
}

</style>
